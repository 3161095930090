export const MENU_ITEMS = [
    {
      id: 0,
      title: 'Главная',
      items: [
  
      ],
      route: '/'
    },
    {
      id: 1,
      title: 'Новости',
      items: [
        
      ],
      route: '/news'
    },
    {
      id: 2,
      title: 'Статьи',
      items: [
        
      ],
      route: '/articles'
    },
    {
      id: 3,
      title: 'Блюда',
      items: [
        {
          id: 0, 
          title: 'Супы',
          route: '#'
        },
        {
          id: 1, 
          title: 'Основные блюда',
          route: '#'
        },
        {
          id: 2, 
          title: 'Хамон',
          route: '#'
        },
        {
          id: 3, 
          title: 'Сыры',
          route: '#'
        },
        {
          id: 4, 
          title: 'Десерты',
          route: '#'
        }      
      ],
      route: '/eat'
    },
    {
      id: 4,
      title: 'Напитки',
      items: [
        {
          id: 0, 
          title: 'Вино',
          route: '#'
        },
        {
          id: 1, 
          title: 'Другое',
          route: '#'
        }     
      ],
      route: '/drinks'
    },
    {
      id: 5,
      title: 'Словарик',
      items: [
        
      ],
      route: '/dictionary'
    }
  ];

  export const HASHTEGS = {
    EAT: 'Еда',
    VINE: 'Вино',
    REUSTARANS: 'Рестораны'
  }

  export const ARTICLES = [
    {
      id: 1, 
      articleId: 1, 
      pictureId: 1, 
      title: 'Еда в Испании в зависимости от региона',
      hashtags: [
        HASHTEGS.EAT
      ]
    },
    {
      id: 2, 
      articleId: 2, 
      pictureId: 2, 
      title: 'Лучшие сорта вин',
      hashtags: [
        HASHTEGS.VINE
      ]
    },
    {
      id: 3, 
      articleId: 3, 
      pictureId: 3, 
      title: 'Лучшие рестораны Испании',
      hashtags: [
        HASHTEGS.EAT,
        HASHTEGS.VINE,
      ]
    },
    {
      id: 4, 
      articleId: 4, 
      pictureId: 3, 
      title: 'Лучшие рестораны Испании',
      hashtags: [
        HASHTEGS.REUSTARANS
      ]
    }
  ];


  export const NEWS = [
    { 
      id: 1,
      title: 'Добавлена статья по Испанским сырам',
      date: '14.01.2020'      
    },
    { 
      id: 2,
      title: 'Сайт sabordeespana.ru запустился и функционирует в рабочем режиме',
      date: '10.01.2020'      
    }
  ]