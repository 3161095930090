import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import axios from 'axios';

// import 'antd/dist/antd.css';


import './Alena.css';

import { MENU_ITEMS } from './constants';
import NoPage from './pages/nopage';
import MainPage from './pages/main';
import NewsPage from './pages/news';
import Article from './pages/article';
import { throws } from 'assert';

export default class Alena extends Component {

    constructor () {
        super ();

        this.state = {
          articles: []
        }

        this.getArticles().then ( (result) => {

          if (result) {

            // парим хаштеги
            let hashtags_ = {};
            for ( let i = 0; i < result.length; i++) {
              const {Hashtegs} = result[i];
              if (Hashtegs && Hashtegs.length > 0) {
                Hashtegs.map ( hashtag => {

                  if (!hashtags_[hashtag]) {
                    hashtags_[hashtag] = {title: hashtag, count: 1};
                  } else
                  {
                    hashtags_[hashtag].count++;
                  }

                  return [];
                } )
              }
            }

            const hashtags = Object.keys(hashtags_).reduce (
              (result, key) => {
                return [...result, hashtags_[key]]
              },
              []
            )

            this.setState({articles: result.reverse(), hashtags});
          }

        })
 

    }

    async getArticles () {
    
       let uri = `https://sabordeespana.ru/php/getArticles.php`;

       let result_ = '';

       /* ПОКА РАЗРАБОТКА! */
       /*
       
       return [{
         "Id":1,
         "Title":"Кухня Испании",
         "Hashtegs":["test", "vine"],
         "Date":"2020.04.28", 
         "Discription": "Тут небольшой текстик какой-то, который я пишу пишу пишу.Тут небольшой текстик какой-то, который я пишу пишу пишу",
         "Type": 'drinks'
      },{"Id":2,
      "Title":"История виноделия в Испании",
      "Hashtegs":["vine", "redvine"],
      "Date":"2020.05.15",
      "Discription": "Тут другой текст, все дела бла бла бла, как делишки, дружише? У меня всё ок",
      "Type": 'eat'
    },{"Id":3,
    "Title":"Бла бла бла",
    "Hashtegs":["vine", "redvine"],
    "Date":"2020.05.30",
    "Discription": "Текстик текстик текст",
    "Type": 'news'
  }]
     
       /* */

       try {

         const res = await axios({
           method: 'get',
           url: uri
           });
   
         const result = res.data;
         if (!result) {
           result_ = null;
           return [];
         }
     
         return result;
       }
       catch (err) {
         console.log(err);
         result_ = null;
         return [];
       } 
       
      return JSON.parse(result_);
       
  }    


    returnReklama = () => (
      <p>
        РЕКЛАМНЫЙ БЛОК
      </p>
    )

    returnMenu = ( props ) => {
      
      return (
        <div className = 'Menu-div' >
          {
            MENU_ITEMS.map( item => {

               const className = 
               item.route === props.location.pathname ?
               'Menu-div-first-level-menu-item underline':
               'Menu-div-first-level-menu-item';

               return <div className = {className}>
                 <Link to = {item.route}> {item.title} </Link>
               </div>
            } )
          }
        </div>

      )
    }

    ArticlePage = (props) => {
      const { articleId } = props.match.params;
      /*const textString = '[{"Id":1,"Title":"Тестовая статья номер один One one","Hashtegs":["test", "vine"],"Date":"2020.04.28"},{"Id":2,"Title":"Чем различаются красные сорта вин","Hashtegs":["vine", "redvine"],"Date":"2020.04.30"}]'
      const textjson = JSON.parse(textString);
      */
      const article = this.state.articles.find ( x => {return x.Id.toString() === articleId});

      if (!article) return <div>Загрузка статьи</div>

      return <Article Article = {article} />

    }

    mainPage = (props) => {
      const { articles } = this.state || [];

      let articles_ = [...articles];

      const path = props.match.path;

      if ( path === '/eat' ) {
        articles_ = articles.reduce( (result, item) => {
          if (item.Type === 'eat') return [...result, item]
          return result
        }, [] )
      }

      if ( path === '/articles' ) {
        articles_ = articles.reduce( (result, item) => {
          if (item.Type === 'article') return [...result, item]
          return result
        }, [] )
      }

      if ( path === '/drinks' ) {
        articles_ = articles.reduce( (result, item) => {
          if (item.Type === 'drinks') return [...result, item]
          return result
        }, [] )
      }

      if ( path === '/news' ) {
        articles_ = articles.reduce( (result, item) => {
          if (item.Type === 'news') return [...result, item]
          return result
        }, [] )
      }

      return <MainPage articles = {articles_} />
    }

    hashtagCloud = () => {

      const {hashtags} = this.state;
      if (!hashtags) return null;

      const upcoming = [...hashtags];

      if (!upcoming || !upcoming[0]) return null;

      const max_font = 20;
      const min_font = 15;

      const max_count = upcoming[0].count;
      const min_count = upcoming[upcoming.length - 1].count;

      const count_arrange = max_count - min_count;

      return upcoming.map (
        (value) => {


          const x = (max_font-min_font) * (value.count - min_count) / count_arrange;

          const font_size = min_font + x;

          return <span style = {{ fontSize: `${font_size}px`}}>{`#${value.title}`}</span>
        }
      )



    }


    render () {
        return <div className = 'GlobalDiv'>

         <div className = 'MainDiv'>
             <div className = 'Header'>
               <div className = 'tooltip Header-adult' data-tooltip = 'материалы сайта предназначены для аудитории старше 18 лет'>
                 18+
               </div>
               <Link to = {'/'}>
               <div className = 'Header-upper-text'>

                 Вкус Испании

               </div>
               </Link>
               <Link to = {'/'}>
               <div className = 'Header-lower-text'>

                 sabordeespana.ru

               </div>
               </Link>
             </div>

              <Switch>
                <Route component={this.returnMenu}/>               
              </Switch>

             <div className = 'Content'>
              <Switch>
                <Route exact path='/' component={this.mainPage}/>
                <Route path='/news' component={this.mainPage}/>  
                <Route path='/articles' component={this.mainPage}/> 
                <Route path='/article/:articleId' component={this.ArticlePage}/>   
                <Route path='/eat' component={this.mainPage}/>   
                <Route path='/drinks' component={this.mainPage}/>   
                <Route path='/dictionary' component={NoPage}/>                 
              </Switch>
             </div> 

             <div className = 'Footer'>
               <div className = 'Footer-img'>
                 © sabordeespana.ru, 2020 <br/>
                 При частичном или полном использовании материалов ссылка обязательна
               </div>
             </div> 

         </div>   

         <div className = 'SideBar'>
           <h1> От автора </h1>
           <h1> Таблица вин по годам </h1>
           <div className = 'SideBar-hashtags'>
             <this.hashtagCloud/>
           </div>

           <a href = '/images/cities/1.jpg'><img src = '../images/cities/1.jpg'/></a>
           <a href = '/images/cities/2.jpg'><img src = '../images/cities/2.jpg'/></a>

           {/*this.returnReklama()*/}     
         </div> 

        </div>
    }
}