import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './default.css';

import { ARTICLES } from '../constants';

const hashtag = ( text ) => (
    <div className = 'hashtag-upper'>
        {`#${text}`}
    </div> 
)

const articleContainer = ( article ) => (
    <Link to = {`/article/${article.Id}`}>
    <div key = {article.Id} className = 'article-container'>
        
        <img src = {`http://sabordeespana.ru/articles/${article.Id}/logo.jpg`} />
        <div className = 'article-bottom'>
            {article.Title} 
            <h1>
              {article.Discription} 
            </h1>
        </div>

    </div>
    </Link>
)


export const Main = (props) => {

    const {articles} = props;

    return <div className = 'main-page flex-center-between'>
        {articles && articles.map (item => {
            return articleContainer(item);
        })}
    </div>
}

export default Main;