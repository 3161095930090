import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import axios from 'axios';


// import 'antd/dist/antd.css';


import './article.css';

export default class Alena extends Component {

    constructor (props) {
        super ();

        this.state = {
           status: 'loading'
        }

        //this.getArticle (props);

    }

    componentDidMount () {
      this.getArticle (this.props);
    }

    async getArticle (props) {
    
        const { Article } = props;

        const { Id } = Article;

         let uri = `https://sabordeespana.ru/articles/${Id}/index.html`;

         
         let result_abzac = ``;
         let try_find_abzac;

         /* ЭТО ТОЖЕ ПОКА РАЗРАБОТКА */

         /*
         
         let result__ = `
         
         В Испании 17 автономных сообществ и 50 провинций
         В Испании 17 автономных сообществ и 50 провинций
         В Испании 17 автономных сообществ и 50 провинций
         В Испании 17 автономных сообществ и 50 провинций
         В Испании 17 автономных сообществ и 50 провинций
         <br/><br/>
                  
         <endabzac>
         В Испании 17 автономных сообществ и 50 провинций, и в каждой есть свои особенности и традиции, в том числе кулинарные. Климат и природные условия определили набор продуктов, характерных для той или иной местности. Но свое слово сказали и завоеватели. Так, финикийцы и карфагеняне научили местных жителей культивировать лозу. Благодаря этому пиренейское королевство сегодня занимает одно из первых мест в мире по виноделию.
         <br/><br/>
         Арабы принесли с собой рис и шафран, без которых невозможно было бы приготовить паэлью. После мавританского владычества на значительно части современной Испании стали распространены специи.
         <br/><br/>
         Сами испанцы после открытия Америки привезли с той стороны Атлантического океана бобы, картофель, помидоры, перец, кабачок и многое другое. В списке более 20 наименований растений, овощей, фруктов, ягод и орехов. Они прижились в Испании и стали неотъемлемой частью местной кухни.
         <br/><br/>
         Но мало того, что традиции регионов сильно отличаются друг от друга, так еще и в каждой семье есть свои рецепты, передаваемые из поколения в поколение. Поэтому даже в соседних деревнях могут готовить одно и то же блюдо по-разному.
         <br/><br/>
         А сегодня к этому добавилась и авторская кухня. Испанские шеф-повара считаются одними из лучших в мире. В гиде «Мишлен» упомянуты более 1,5 тысяч испанских ресторанов. 166 из них отмечены одной звездой, 28 – двумя, а 9 получили высшую оценку – три звезды.'
         `;

         try_find_abzac = result__.indexOf('<endabzac>');

         if (try_find_abzac > -1) {

          result_abzac = result__.slice(0, try_find_abzac);
          result__ = result__.slice(try_find_abzac)

         }


         document.getElementById('one-article-content').innerHTML = result__;
         return document.getElementById('one-article-content-abzac').innerHTML = result_abzac;
/* */
         

         let result_ = '';
       
         try {

           const res = await axios({
             method: 'get',
             url: uri
             });
     
           const result = res.data;
           if (!result) {
             result_ = null;
             return;
           }
       
           result_ = result;
         }
         catch (err) {
           console.log(err);
           result_ = null;
           return;
         }
         

         try_find_abzac = result_.indexOf('<endabzac>');

         if (try_find_abzac > -1) {

          result_abzac = result_.slice(0, try_find_abzac);
          result_ = result_.slice(try_find_abzac)

         }

         document.getElementById('one-article-content-abzac').innerHTML = result_abzac;
         document.getElementById('one-article-content').innerHTML = result_;
         
    }

    hashtag = ( text ) => (
        <div className = 'hashtag-upper-article'>
            {`#${text}`}
        </div> 
    )

    render () {

       const { Article } = this.props;

       const { Id, Title, Hashtegs } = Article;

       const Date_arr = Article.Date.split('.');
       const date = `${Date_arr[2]}.${Date_arr[1]}.${Date_arr[0]}`;
 
       const BGStyle = {
        backgroundImage: `url('https://sabordeespana.ru/articles/${Id}/logo.jpg')`,
        backgroundRepeat:' no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
       }

       return <div className = 'one-article'>
           <div className = 'one-article-header'>
               <div className = 'one-article-header-title'>
                 <span>{Title}</span> 
               </div>     

               <div className = 'one-article-header-lower'>
                  <div className = 'one-article-header-date'>
                   Дата: {date}  
                  </div>  
               </div>

               <div className = 'one-article-content-upper-abzac'>
                  <div id = 'one-article-content-abzac'  className = 'noselect'/>
               </div>

               <div style = {BGStyle} className = 'one-article-header-background'/>


           </div> 

           <div className = 'one-article-content-container'>
             <div id = 'one-article-content' className = 'noselect'/>
           </div>

           <div className = 'one-article-header-hashtegs'>
                   Теги: 
                   {Hashtegs.map (item => {
                     return this.hashtag(item);
                    })}                 
            </div>

       </div>
    }


}