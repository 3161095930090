import React, { Component } from 'react';

import './default.css';

const NoPageInside = () => {
    return <img src= 'images/bottle.svg'/>
}

export const NoPage = () => (
    <div className = 'nopage content-main flex-center'>
        {NoPageInside()}<p>страница <br/>в разработке</p>
    </div>
)

export default NoPage;
